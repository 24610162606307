import React from 'react';
import { Link } from 'react-router-dom';

const TsAndCs = () => {
  return (
    <>
      <div className='info-img-bg'>
        <h1>Terms & Conditions</h1>
      </div>
      <div className='info-white-bg shadow'>
        <div className='info-content'>
          <h2>Dating Services Terms of Use</h2>
          <p>(Terms of Use revised on July 14, 2023)</p>
          <h3>Preamble</h3>
          <p>
            These Terms of Use are entered into between Loveisincyprus.com
            (hereinafter “Love is in Cyprus”) and any individual who is a Member
            or Subscriber (hereinafter “you” or “your”).
          </p>
          <br />
          <p>
            Love is in Cyprus offers services which aim to facilitate dating
            between individuals for personal, entertainment and non-commercial
            purposes through electronic communications networks (hereinafter the
            “Services”).
          </p>
          <br />
          <p>
            For each of the Services, certain features are available to Members
            free of charge while other features are paid for and accessible only
            by Subscribers.
          </p>
          <br />
          <p>
            Registration to one or more Services as well as use of the Services
            implies the acceptance of these Terms of Use by the Member or
            Subscriber in full. Therefore, by creating an Account, the Member or
            Subscriber acknowledges that he/she is fully informed of and bound
            by all the provisions of the Terms of Use.
          </p>
          <br />
          <p>
            The Terms of Use are the property of Love is in Cyprus and of the
            company owning Love is in Cyprus. Accordingly, any dissemination,
            exploitation, representation, reproduction or other use, in whole or
            in part, on any medium, of the Terms of Use for purposes other than
            strictly personal and non-professional, is subject to Love is in
            Cyprus's prior authorization. Without this authorization, the
            infringing party may be subject to criminal and civil prosecution as
            provided for by law.
          </p>
          <h3>Article 1. Definitions</h3>
          <p>
            The words or terms used in these Terms of Use and commencing with a
            capital letter shall have the following meaning:
          </p>
          <br />
          <p>
            “Account” refers to the strictly personal interface, which is
            accessible to Members and Subscribers, permitting them to be
            identified on the Websites, benefit from the Services they have
            registered for or subscribed to, complete and modify their Profile
            and, if applicable, renew or modify their Subscription.
          </p>
          <br />
          <p>
            “Event” or “Activity” both refer to, depending on the Website
            delivering the Service, any free or paid event allowing Members and
            Subscribers and, if applicable, their Guests to meet in person,
            offered in accordance with Love is in Cyprus' Terms and Conditions
            for Events, the defined terms of which are also used herein, and
            article 10 of these Terms of Use.
          </p>
          <br />
          <p>
            “Member” refers to a user who has access to one of the Services
            after validly registering for free on the Websites.
          </p>
          <br />
          <p>
            “Paid Services” refers to all paid Services available to
            Subscribers.
          </p>
          <br />
          <p>
            “Profile” refers to a space provided to a Member or Subscriber,
            which comprises of the Member's or Subscriber's self-description,
            including his/her characteristics, photos and videos, and which is
            accessible by other Members and Subscribers.
          </p>
          <br />
          <p>
            “Services” refers to all services, whether paid or free of charge,
            available to Members or Subscribers, provided on the Websites by
            Love is in Cyprus.
          </p>
          <br />
          <p>
            “Subscriber” refers to a user of one or more Services who has
            purchased a Subscription to one or more Services.
          </p>
          <br />
          <p>
            “Subscription” refers to a fixed price giving access to Services,
            including paid Services, for a limited period specified in the
            relevant Subscription purchased by a Subscriber.
          </p>
          <br />
          <p>“Terms of Use” refers to this contract.</p>
          <br />
          <p>
            “Third-party Websites” refers to any third-party Internet websites
            or mobile application.
          </p>
          <br />
          <p>
            “Visitor” refers to a user who visits the Website without
            registering or without connecting to his/her Account.
          </p>
          <h3>Article 2. Access, registration and Subscription</h3>
          <h4>
            2.1. Procedure and terms of access and registration to the Services
          </h4>
          <p>
            Registration to the Services is free. The Visitor, Member or
            Subscriber shall exclusively bear the costs for the means to access
            the Services as well as for the use of such means. Acceptance of
            these Terms of Use when registering constitutes the formal
            conclusion of the membership contract for the Services. This results
            in becoming a Member, discovering the Services and gives access to
            free functionalities of the Services described in article 4.1 below.
          </p>
          <br />
          <p>
            To register and thus become a Member, every Visitor who is
            interested in the Services must be at least 18 years old, meet the
            conditions for registration or access to the relevant Services, have
            the requisite legal capacity to enter into a contract, read and
            fully agree with these Terms of Use, the Cookie Policy and the
            Privacy Policy and complete the mandatory fields in the registration
            form. It is specified that a Member cannot create more than one
            Account with the same email address.
          </p>
          <br />
          <p>
            Once registered, the Member completes his or her Profile, with the
            optional or mandatory information as applicable. This information is
            published on the Site and shared with other Members through the
            Services. The distribution by the Member of his or her personal
            contact details (e-mail address, postal address, telephone, etc.) in
            his or her Profile description is expressly prohibited by Love is in
            Cyprus (see article 5.1 below).
          </p>
          <br />
          <p>
            The Member or Subscriber is responsible for the use of his/her
            identification elements by third parties or the actions or
            statements made by an intermediary on his/her Account, whether they
            are fraudulent or not, and will indemnify Love is in Cyprus for any
            claim arising in connection with these, other than in the event of
            fault exclusively attributable to Love is in Cyprus or technical
            failure of the Services. Moreover, Love is in Cyprus is not obliged
            and does not have the technical means to verify the identity of
            individuals registering to the Services. If the Member or Subscriber
            has reason to believe that another person is using his/her
            identification elements or his/her Account, he/she must inform Love
            is in Cyprus immediately.
          </p>
          <br />
          <h4>2.2. Procedure and conditions for Subscription</h4>
          <p>
            Subscription to the Services is paid for. The Member may purchase a
            Subscription and, thus, become a Subscriber and benefit from the
            functionalities of his/her Subscription. In order to do this, the
            Member must first identify him/herself using his/her email address
            and his/her mobile number. After choosing his/her Subscription and
            way of payment, the Member must submit his/her payment. This last
            step formally concludes the membership contract to the Paid Services
            and the complete, unreserved acceptance of the Terms of Use. Once
            the payment has been submitted, the Subscriber is directed to a
            payment confirmation page. Love is in Cyprus acknowledges receipt of
            the purchase of a Subscription by sending an email.
          </p>
          <h3>Article 3. Right to cancel</h3>
          <p>
            Pursuant to article 29 of the Consumer Contracts (Information,
            Cancellation and Additional Charges) Regulations 2013, the Member is
            informed that he/she has fourteen days from the purchase of a
            Subscription to exercise his/her right to cancel, without incurring
            any liability and without giving a reason from within the user
            settings menu, or by sending a clear statement requesting this to
            the e-mail address: customercare@loveisincyprus.com. Only requests
            relating to the exercise of this right to cancel will be processed
            from these contact addresses. In order for the cancellation to be
            effectively exercised, the Subscriber must send his/her request
            within the fourteen-day cancellation period. The Subscriber may, if
            he/she wishes to, access the Paid Services immediately without
            waiting for the end of the cancellation period, according to article
            36 (1) of the Consumer Contracts (Information, Cancellation and
            Additional Charges) Regulations 2013.
          </p>
          <br />
          <p>
            If the Subscriber does not wish to access the Paid Services
            immediately, he/she may leave the Website after submitting his/her
            payment and wait for the expiry of the fourteen-day period.
            Nevertheless, if the Subscriber connects to his/her Account and uses
            the Services before the end of the cancellation period, this first
            log in from the purchase of a Subscription constitutes an express
            request by the Subscriber to have immediate access to the Paid
            Services.
          </p>
          <br />
          <p>
            In any event, immediate access to the Services does not deprive the
            Subscriber from his/her right to cancel, which remains valid until
            the expiry of the fourteen-day period. Once their Subscription is
            paid, if the Subscriber does not access any of the Paid Services
            within the next 14 days, the Subscriber will be refunded and will
            have limited access to the Website features as unpaid user. The
            reimbursement shall be made within 14 days from the date Love is in
            Cyprus was informed of the Subscriber's decision to cancel, by the
            same means of payment which was used by the Subscriber for the
            initial transaction, save for any instruction from the latter to the
            contrary. The Subscriber will find additional information on the
            Help/Contact us page about the scope and application of the right to
            cancel.
          </p>
          <h3>Article 4. Service features</h3>
          <h4>4.1 Free features available to Members</h4>
          <p>
            Registration to the Services allows individuals to become a Member
            and provides access to free features. These features are not the
            same depending on the Services and may change over time. Becoming a
            Member does not allow you to use all the functionalities of the
            Website unless specified on the Website according to certain
            temporary or conditional circumstances. Depending on the Services
            and subject to restrictions set by Members if any, free features
            notably include the creation of the Profile, certain search
            features, and consultation of other Members' Profiles. Communication
            with other Members generally requires a Subscription. Every Member
            can control, through his/her Account, the options which allow
            him/her to be visible or not on the Website and thus able to be
            contacted, if applicable, through those. A Member may access the
            Website provided that he/she satisfies the conditions applicable to
            Members of the Website.
          </p>
          <br />
          <h4>4.2 Paid features available only to Subscribers</h4>
          <p>
            Purchase of a Subscription allows access to the features described
            on the Website.
          </p>
          <h3>Article 5 Services and Products</h3>
          <h4>5.A Services</h4>
          <h4>
            5.A1 Love is in Cyprus obligations in relation to the Services
          </h4>
          <p>
            The purpose of the Services, including the Paid Services, is not
            organising 'real life', in person dates between the Members and/or
            the Subscribers, nor marital counselling leading to marriage nor
            establishing a stable union, regardless of the Member's or
            Subscriber's personal motivations in this respect. Love is in Cyprus
            expressly excludes all liability in connection with events of any
            nature, which could take place between Members or Subscribers during
            online interactions, whether through the Websites or through Third
            Party Websites, or during 'offline' or 'real life', in person dates
            between Members or Subscribers arising from use of the Services.
          </p>
          <br />
          <p>
            The purpose of the Services, including the Paid Services, is not the
            provision of access to the Internet nor the provision of electronic
            communication services to the public. As a result, Love is in Cyprus
            does not verify the actual identity of Members or Subscribers when
            they connect to the Websites. Further, they do not control nor
            moderate exhaustively any of the content, which the Members and
            Subscribers may publish or upload on the Services under their sole
            liability. However, the photographs and the description featuring on
            the Profile of every Member or Subscriber may be subject to review
            and moderation by our team. Therefore, Members and Subscribers are
            reminded that they are prohibited from disclosing any information to
            other Members or Subscribers, which allows them to be identified or
            located (surname, postal address, email, telephone number, Website…)
            with the exception of their username, through the Services. Love is
            in Cyprus reserves the right to moderate, suspend or delete anytime
            any Member or Subscriber, without prejudice to any monies already
            paid or then due to Love is in Cyprus if the concerned Member or
            Subscriber don't comply with their obligations described in article
            5.A2.1 and 5.A2.2.
          </p>
          <br />
          <h4>5.A2 Obligations of Members and Subscribers</h4>
          <p>
            The provisions of this article apply from the registration or
            Subscription and shall remain applicable for the entire duration of
            use of the Services, without prejudice to the provisions of article
            9 below. In order to use the Services, the Member or Subscriber
            agrees to comply with any laws in force, respect the rights of other
            Members and Subscribers, as well as observe the obligations defined
            below.
          </p>
          <br />
          <h4>5.A2.1. General obligations:</h4>
          <p>The Member or Subscriber has the following general obligations:</p>
          <br />
          <div className='bullets'>
            <ul>
              <li>
                Agree with and conform to the preservation of the thematic, the
                character, the nature and the spirit of Love is in Cyprus (as
                described in the About Us section) and of the longstanding
                specifics of the majority of the residents of the Republic of
                Cyprus; and thus should either refrain from registering to Love
                is in Cyprus in the case he/she does not fit with, or delete
                his/her profile if already registered;
              </li>
              <li>
                Conduct his/herself in an honest manner towards Love is in
                Cyprus and other Members or Subscribers;
              </li>
              <li>
                Respect the intellectual property rights of the content provided
                by Love is in Cyprus and possible related companies as well as
                the other Members and Subscribers;
              </li>
              <li>
                Ensure that he/she defines the limits of his/her private life
                and that he/she is responsible for communicating only
                information which would not prejudice him/her by its the
                dissemination through the Services.
              </li>
            </ul>
          </div>
          <br />
          <p>
            In the event of inappropriate profile on the Website and Services,
            not meeting any and all of the General Obligations, Love is in
            Cyprus reserves the right to permanently delete the relevant
            Member's or Subscriber's Account(s) on the Website and Services
            without prior notice, without any consequence to any and all
            possible monies paid by the Subscriber (if applicable), and without
            prejudice to the provisions described in 9.4 of the present Terms of
            Use.
          </p>
          <br />
          <h4>5.A2.2. Essential obligations:</h4>
          <p>
            The Member or Subscriber generally has the essential obligations
            (hereinafter, the “Essential Obligations”) to:
          </p>
          <br />
          <div className='bullets'>
            <ul>
              <li>
                Use the Services while respecting their purpose, which is to
                allow individuals to meet for personal, entertainment and
                non-commercial purposes;
              </li>
              <li>
                Not to use the Services for professional, commercial, profit
                (advertising, soliciting clients etc.) or generally non-personal
                purposes;
              </li>
              <li>Not use the Services for solicitation or prostitution;</li>
              <li>
                Not post, display or disseminate, in any form whatsoever, false
                information or content;
              </li>
              <li>
                Refrain from posting any statement or content that violates the
                rights of others or is defamatory, and, in general, any
                statement or content contrary to the purpose of the Services,
                the laws and regulations in force, the rights of persons or
                public morals. In particular, photos and any information, data
                or files provided by a Member or Subscriber to Love is in Cyprus
                must be decent and only relate to the Member or, if they relate
                to a third party, be used with his/her express authorisation and
                under the exclusive responsibility of the Member or Subscriber
                concerned;
              </li>
              <li>Not harass other Members or Subscribers;</li>
              <li>
                Not mention on the Website any personal information provided by
                a Member or Subscriber to Love is in Cyprus (e-mail address,
                postal address, telephone number, etc.) that would allow a
                Visitor, Member or Subscriber to contact another Member or
                Subscriber without using the Services;
              </li>
              <li>
                Not post, display or disseminate, in any form whatsoever,
                information or content that has the effect of diminishing,
                disrupting, preventing the normal use of the Website and
                Services, interrupting and/or slowing the normal flow of
                communications between Members and/or Subscribers through the
                Services, such as software, viruses, logic bombs, mass mailing,
                etc. Love is in Cyprus reserves the right to delete mass
                messages sent by a Member or a Subscriber, and thus the right to
                delete such Member of Subscriber, in order to preserve the
                quality of normal use of the Services.
              </li>
              <li>
                Not post, display or disseminate, in any form whatsoever,
                information or content incorporating hyperlinks to Third-party
                Websites on the Website that are illegal and/or not conform with
                the purpose of the Services;
              </li>
              <li>
                Not post photo(s) or other visual content which features minors;
              </li>
              <li>
                Use the mobile phone number of the Member or Subscriber and
                confidential one time password strictly to log in to the
                Services.
              </li>
            </ul>
          </div>
          <br />
          <p>
            Therefore, the Member or Subscriber must not, this list not being
            exhaustive, communicate, disseminate, share, make accessible, in any
            way or for any reason whatsoever, its passwords and/or usernames to
            any third party, whoever that is. A breach of the Essential
            Obligations thus defined constitutes a serious breach by the Member
            or Subscriber of its contractual obligations.
          </p>
          <br />
          <p>
            Without prejudice to the provisions of Article 9 below, in the event
            of breach by a Member or Subscriber of one or more of the General
            Obligations or the Essential Obligations, Love is in Cyprus may
            terminate the relation and permanently delete the relevant Member's
            or Subscriber's Account(s) on the Website and Services. In the event
            of breach by a Member or Subscriber of one or more of the General
            and/or Essential Obligations, and namely in the event of
            inappropriate behaviour on the Website and Services, Love is in
            Cyprus may permanently or temporarily prohibit all access or
            subscription of the relevant Member or Subscriber to any or all
            parts of the Website.
          </p>
          <br />
          <h4>5.B Products</h4>
          <h4>5.B.1. Website</h4>
          <p>
            The viewing of the website and browsing of the website automatically
            requires you to accept our Cookies policy. When you enter into a
            transaction with us, you automatically accept the trading terms and
            conditions as enclosed.
          </p>
          <br />
          <h4>5.B.2. Authority to purchase</h4>
          <p>
            The User is required to have the authority to purchase and remain
            liable for the cost incurred. If the purchase is made on behalf of a
            third party they must have the authority to make the purchase and
            ensure payment or become liable for the cost of the purchase. The
            User should ensure the information provided is accurate and true to
            your knowledge. You agree not to impersonate any other person or
            entity or to use a false name or a name that you are not authorised
            to use. All cases of Fraud are reported to the Police.
          </p>
          <br />
          <h4>5.B.3. Indemnity</h4>
          <p>
            You agree to fully indemnify, Ex Florum Ltd and our directors,
            employees and suppliers, harmless immediately on demand, from and
            against all claims, liability, damages, losses, costs and expenses,
            including legal fees, arising out of any breach of the Conditions by
            you or any other liabilities arising out of your use of the Website,
            or the use by any other person accessing the Website using your User
            Information.
          </p>
          <br />
          <h4>5.B.4. Products</h4>
          <p>
            We retain the right to: Control all items and products sold on the
            Website without notice to Users and we shall not be made liable to
            you or any third party for any modification to or withdrawal of a
            product from the Website.
          </p>
          <br />
          <h4>5.B.5. Orders</h4>
          <p>
            All orders are subject to acceptance in accordance with product
            availability and payment terms. Should the item purchased not be in
            stock you will be contacted by e-mail and offered the option to
            cancel your order and receive a refund, or to wait for the item to
            return to stock, or to accept an alternative product.
          </p>
          <br />
          <h4>5.B.6. Product specification</h4>
          <p>
            Each Product purchased is sold subject to its Product specification.
            Every effort is made to ensure that each specific measurement is as
            accurate as possible and based on recognised industry standards of
            conformity and tolerances. Ex Florum Ltd accepts no liability for
            any loss or damages occurred from product supplied out-of tolerance
            in excess of the cost of replacing the faulty product.
          </p>
          <br />
          <h4>5.B.7. Product guarantee</h4>
          <p>
            To the fullest extent permissible under applicable law, we disclaim
            any and all warranties of any kind, whether express or implied, in
            relation to the Products. Should the product prove to have been
            manufactured faulty then our liability extends on to the cost of the
            replacement of the faulty product.
          </p>
          <br />
          <h4>5.B.8. Refusal of transaction</h4>
          <p>
            We reserve the right to refuse to process any order for any reason
            or refuse service to anyone at any time at our sole discretion. We
            retain the right to credit a transaction and cancel the order at any
            stage of the process prior to delivery.
          </p>
          <br />
          <h4>5.B.9. Account transactions</h4>
          <p>
            We reserve the right to cancel or suspend an account should the
            payment Terms and Conditions of the Users account not be met. By
            applying for a credit account, you automatically authorise us to
            transmit information or to obtain information about you and your
            organisation from third parties including but not limited to your
            debit card or credit-card number or credit reports to authenticate
            your identity, to validate your debit card or credit card, to obtain
            an initial debit card or credit card authorisation and to authorise
            individual or organisation purchase transactions.
          </p>
          <br />
          <h4>5.B.10. Price confirmation</h4>
          <p>
            All prices quoted on the web-site are inclusive of VAT at the
            current rates. The total cost of your order is the price of the
            Products ordered plus Shipping Charges. You will receive an emailed
            confirmation of our receipt of your order. Credit/Debit Card
            Payments will be debited and cleared from your account before the
            dispatch of your Product(s). Account holders must ensure their
            account is 'in terms' prior to any order being dispatched.
          </p>
          <br />
          <h4>5.B.11. Payment details</h4>
          <p>
            We retain/store no information on any credit/debit card details in
            the company. The company receives confirmation of your payment
            direct from our payment processor.
          </p>
          <br />
          <h4>5.B.12. Shipping and handling</h4>
          <p>
            Our Intention is to supply all orders in line with current
            legislation within 30 days, However we do try to fulfil all orders
            within 3 - 7 working days from stock. As described within our Terms
            and Conditions every reasonable effort will be made to contact you
            should the delivery date be in excess of 7 days. Ex Florum Ltd
            accepts No liability for any loss or damages caused from late
            delivery of any order. Customers can call and discuss specific
            delivery requirements and short notice orders and every effort will
            be made to accommodate individual and unique circumstances. However
            we cannot be held liable for any costs or damages which result from
            any failure in meeting any agreed delivery circumstances
          </p>
          <br />
          <h4>5.B.13. Redelivery of goods</h4>
          <p>
            It is important that there is someone to accept your delivery as
            each consignment will require a signature. Your order can be
            delivered between 8am-7pm Monday to Friday and Saturday from
            8am-1pm. If we are unable to deliver your order to you, the goods
            will be returned to our warehouse at a cost of 10% of your original
            order value or EUR 10 whichever is greater. You will be charged the
            standard rate of shipping again to redeliver your goods. If you have
            missed a delivery please contact our carrier who will have left a
            calling card in the first instance. If you are unable to contact our
            carrier, please contact our customer service:
            customercare@loveisincyprus.com.
          </p>
          <br />
          <h4>5.B.14. Important</h4>
          <p>
            Always check your purchases on receipt for shortages or damage. You
            must report any problems within 48 hours of delivery. Claims for
            damage within transit require you to keep all packaging for
            inspection. We MUST insist you follow these instructions for claims
            and any problems reported after 48 hours from delivery are at the
            discretion of Ex Florum Ltd. Please quote your order number in all
            correspondence. Proof of Delivery: You agree that proof of delivery
            supplied by our delivery company is sufficient evidence to establish
            that goods have been received.
          </p>
          <br />
          <h4>5.B.15. Returns and refund policy</h4>
          <p>
            You are entitled to cancel your order and return your goods within
            14 working days of placing your order for a refund, excluding the
            cost of delivery and a 10% handling charge (this is to cover the
            cost of inspection to ensure goods are in a saleable condition and
            returned to our stock). To return your goods to us you need to
            contact us by email: customercare@loveisincyprus.com and quote the
            invoice number supplied to you. Your refund will be paid within 30
            days from receipt of the goods by us. In the event that goods need
            to be returned, it is the responsibility of the customer to cover
            the cost of adequate insurance and the cost of the return. This
            cancellation policy does not affect your rights when we are at fault
            - for example, if goods are faulty or damaged. Any goods returned
            must be unused and in a saleable condition.
          </p>
          <h3>Article 6. Services, fees, terms of payment and renewal</h3>
          <h4>6.1. Fees and terms of payment of Paid Services</h4>
          <p>
            The fees and the terms of payment are described on the Website.
            Members may pay either by bank card (on the internet or through a
            mobile service) or by using an online payment service (Direct Debit
            etc.) according to the options given on the relevant Website. The
            fees are indicated in Euros EUR (inclusive of all taxes).
          </p>
          <br />
          <h4>6.1.1. Free Membership until 31 August 2023</h4>
          <p>
            This membership with free of charge access to all features of the
            Services is an incentive granted to the first 1,000 Members but in
            no way and under no circumstances can be deemed as a lifetime
            membership or as a lifetime free of charge membership. This Free
            Membership incentive will expire at the time and once the Website{' '}
            <Link to='/'>loveisincyprus.com</Link> accounts for 1,000 (one
            thousand) Members from which the said members will have to renew
            their Membership at the prevailing respective prices for the
            selected periods (one month, six months or a year) should they wish
            to keep on using the Services.
          </p>
          <br />
          <h4>6.2. Subscription renewal terms</h4>
          <p>
            On the expiry of each period of validity, the Subscription shall be
            renewed for periods equivalent to that originally chosen, unless
            terminated by the Subscriber in accordance with the terms and
            conditions in article 9 below. In particular cases, shorter renewal
            periods may apply as identified on the Website at the time of
            Subscription. Once renewed, the Subscription will be charged to the
            Subscriber at the standard (non-discounted) rate. In addition,
            information on the duration of the Subscriber's Subscription is
            available to Subscribers in the “My Account” section, which allows a
            Subscriber to manage his/her Subscription and, if applicable, the
            termination of the automatic renewal.
          </p>
          <br />
          <h4>6.3. Other services by third parties</h4>
          <p>
            The Subscriber may also purchase other ad hoc services, such as
            buying mobile phone applications or special offers. In this
            particular case, these ad hoc services are purchased from other
            providers and in accordance with other terms and conditions of
            payment to those used for the Services provided by Love is in Cyprus
            (for example, iTunes for the purchase of an iPhone application). The
            billing terms, the payment terms as well as the terms for renewal
            and termination applicable to those ad hoc services and those
            special offers will be communicated to the Subscriber by the third
            party and shall be accepted by the Subscriber before his/her
            purchase. In any event, the purchase of smartphone applications
            published by Love is in Cyprus does not in any case give access to
            Paid Services without the purchase of a Subscription.
          </p>
          <h3>Article 7. Intellectual Property</h3>
          <h4>7.1. Content disseminated on the Services</h4>
          <p>
            All Services names, trademarks, logos, graphics, photographs,
            animations, videos and texts contained on the Websites and within
            the Services are the exclusive property of Love is in Cyprus and, if
            applicable, its partners and may not be reproduced, used or
            communicated without the express authorisation of the companies of
            Love is in Cyprus or its partners, subject to legal action. The
            rights of use granted to the Member and Subscriber are limited to
            private and personal use as part of and for the duration of the
            registration to the Services. Any other use by the Member or
            Subscriber is prohibited.
          </p>
          <br />
          <p>
            The Member or Subscriber is prohibited from, among other actions,
            modifying, copying, reproducing, downloading, broadcasting,
            transmitting, commercially exploiting and/or distributing the
            Services, Website pages or computer codes of the elements composing
            the Services and Websites, in any way whatsoever, subject to legal
            action.
          </p>
          <br />
          <h4>7.2. Content disseminated by the Members and Subscribers</h4>
          <p>
            The Member or Subscriber grants Love is in Cyprus a license to use
            the intellectual property rights arising from the content provided
            by him/her in connection with his/her registration or Subscription
            and his/her use of the Services or the display of his/her Profile on
            the Services. This license specifically includes the right of Love
            is in Cyprus to reproduce, represent, adapt, translate, digitalise,
            use for the purposes of the Services or sub-license the content
            provided by the Member or Subscriber (information, images,
            description, search criteria, etc.) on all or parts of the Services
            and on all the Websites, in the emails by Love is in Cyprus, and, in
            general, on all electronic communication media (e-mail, Internet,
            mobile application) in connection with the Services.
          </p>
          <br />
          <p>
            The Member or Subscriber expressly authorizes Love is in Cyprus to
            modify this content in order to comply with the graphic charter of
            the Services or other communication media referred to above, and/or
            to make it compatible with its technical functionalities or the
            formats supported by the relevant media. These rights are granted on
            a worldwide basis and for the duration of the Terms of Use between
            the Member or Subscriber and Love is in Cyprus. The Member or
            Subscriber may not copy, reproduce, or otherwise make use of the
            content produced by other Members or Subscribers, other than
            strictly for the needs of use of the Services for personal and
            private purposes.
          </p>
          <h3>Article 8. Representations and Warranties</h3>
          <h4>8.1. Operation of the Websites and Services</h4>
          <h4>8.1.1</h4>
          <p>
            To use the Services, the Member or Subscriber must have the skills,
            equipment and software necessary for use of the Internet or the
            mobile applications of the Services. Love is in Cyprus has security
            measures in place in connection with the use of the Services.
            However, the Member or Subscriber acknowledges that, due to the
            nature and limitations of the Internet, Love is in Cyprus cannot
            warrant that any connection to the Internet or mobile applications
            will be completely secure.
          </p>
          <br />
          <h4>8.1.2</h4>
          <p>
            The Member or Subscriber must have the equipment, including the
            software and appropriate settings, necessary for the good operation
            of the Services: the latest version of the Internet browser,
            activated JavaScript functions, permitted session cookies and
            accepted 'pop-up' windows.
          </p>
          <br />
          <h4>8.1.3</h4>
          <p>
            Love is in Cyprus does not warrant that the Member or Subscriber
            will be able to use the Services if he/she uses a “pop-up killer”
            tool or an equivalent; in this case, this function must be disabled
            before using the Services.
          </p>
          <br />
          <h4>8.1.4</h4>
          <p>
            Love is in Cyprus does not warrant that the Member or Subscriber
            will be able to use the Services if his/her Internet service
            provider fails to provide an adequate level of service. Similarly,
            if applicable, the use of smartphone applications purchased by the
            Member or Subscriber directly from the provider of the application
            implies that the latter must have a smartphone and a satisfactory
            connection.
          </p>
          <br />
          <h4>8.1.5</h4>
          <p>
            Love is in Cyprus is not responsible for any lack of functionality,
            lack of access or poor conditions of use of the Websites and
            Services attributable to unsuitable equipment, to the internal
            functionality failures of the Member's or Subscriber's service
            provider, the overloading of the Internet network and for all other
            reasons external to Love is in Cyprus constituting a force majeure
            event.
          </p>
          <br />
          <h4>8.1.6</h4>
          <p>
            The operation of the Services may be interrupted temporarily due to
            maintenance, updates or technical improvements, or to update the
            content and/or the way it is presented. If possible, Love is in
            Cyprus will notify its Members and Subscribers before any
            maintenance operations or updates which may impact the Services.
          </p>
          <br />
          <h4>
            8.2. Information and content provided by the Members or Subscribers
          </h4>
          <h4>8.2.1</h4>
          <p>
            Information provided by a Member or Subscriber to any company or
            service of Love is in Cyprus must be accurate and true. The
            consequences that disclosing this information may have on the
            Member's or Subscriber's life or the lives of other Members or
            Subscribers are the exclusive responsibility of the Member or
            Subscriber concerned. The Member or Subscriber discloses and
            disseminates information, data, text, content and images that
            concern it through the Services on his/her own initiative.
            Accordingly, he/she waives the right to make any claim against Love
            is in Cyprus and its related companies, in particular on the basis
            of the possible infringement of his/her image or personality rights,
            honour, reputation or secrecy of his/her private life, that may
            result from the distribution or dissemination of information that
            concern him/her under the terms and conditions established in the
            Privacy Policy to the extent that the Member or Subscriber has
            previously given his/her voluntary and express consent to such
            dissemination as a result of his/her registration to the Services,
            according to the Terms of Use.
          </p>
          <br />
          <h4>8.2.2</h4>
          <p>
            Love is in Cyprus cannot be held liable for the accuracy or
            inaccuracy of the information and content provided by other Members,
            Subscribers and/or the Member or Subscriber him/herself, nor the
            consequences from the use of this information and content.
            Similarly, Love is in Cyprus cannot be held liable for any content
            disseminated by a Member or Subscriber that may potentially infringe
            the rights of one or more of the other Members, Subscribers or third
            parties and for which Love is in Cyprus demonstrates that it was not
            informed by a Member, Subscriber or third party, or did not have
            actual and prior knowledge of before its dissemination or did not
            commit a breach in the performance of any of its contractual
            obligations under these Terms of Use.
          </p>
          <br />
          <h4>8.2.3</h4>
          <p>
            The quality of the Services expected by both Love is in Cyprus and
            its Members and Subscribers implies observing a certain level of
            ethical conduct in the expression and behaviour of the Members and
            Subscribers, respecting the rights of third parties, as well as the
            laws and regulations in force. To meet this demand for quality,
            individual responsibility and ethics, Love is in Cyprus allows any
            Member or Subscriber to report any content (photography, text,
            video), behaviours or comments made by another Member or Subscriber
            that appear to be in violation of the laws and regulations in force,
            regarding the purpose of the Services or third-party rights.
          </p>
          <br />
          <p>
            As a result, Members and Subscribers acknowledge and accept that the
            data they provide, as well as their behaviour or comments made
            through the Services, may be reported by other Members or
            Subscribers and may be subject to acts of moderation and/or control
            by Love is in Cyprus on the basis of objective assessment criteria.
            In the event this report or control reveals that a Member or
            Subscriber violated the laws and regulations in force or his/her
            contractual obligations under these Terms of Use, Love is in Cyprus
            may restrict, restrain, suspend or delete this Member or Subscriber
            by removing his/her account according to article 9 below. Depending
            on the behaviour or the comments made by Members or Subscribers, the
            moderation team may decide to block any new registration by the
            relevant person.
          </p>
          <br />
          <h4>8.3. Links and external resources</h4>
          <p>
            Love is in Cyprus is only liable for the hyperlinks it creates in
            the Website and Services and does not exercise any control over the
            Third Party Websites and external resources (third-party websites or
            mobile applications, social networks, etc.) to which the hyperlinks
            accessible on the Websites and the Services redirect. Love is in
            Cyprus cannot be held liable for the provision of links directing to
            Third Party Websites and cannot be held liable for their content,
            advertisements, products, features, services or any other elements
            available on or based on the use of such Third Party Websites. It is
            reminded that the consultation and use of Third Party Websites is
            governed by their conditions of use, and that such consultation and
            use will be made under the Member's or Subscriber's full liability.
            Any issues relating to a link must be reported to Love is in Cyprus
            using the contact details or the{' '}
            <Link to='/contact-us'>contact Form</Link>.
          </p>
          <br />
          <h4>8.4 Liability and Indemnity</h4>
          <h4>8.4.1</h4>
          <p>
            Love is in Cyprus is only responsible for foreseeable loss and
            damage caused by Love is in Cyprus, if Love is in Cyprus fails to
            comply with these Terms of Use or to use reasonable care and skill.
            Loss or damage is foreseeable if either it is obvious that it will
            happen or if, at the time the membership relation was made both Love
            is in Cyprus and the Member or Subscriber knew it might happen.
          </p>
          <br />
          <h4>8.4.2</h4>
          <p>
            Love is in Cyprus may not be held liable for any damages incurred by
            a Member or Subscriber in the event those are caused solely by the
            latter or if Love is in Cyprus is not in breach of these Terms of
            Use and its statutory obligations.
          </p>
          <br />
          <h4>8.4.3</h4>
          <p>
            If defective digital content, which Love is in Cyprus has supplied,
            damages a device or digital content belonging to a Member or
            Subscriber, and this is caused by Love is in Cyprus's failure to use
            reasonable care and skill, Love is in Cyprus will either repair the
            damage or pay him/her compensation. However, Love is in Cyprus will
            not be liable for damage which the Member or Subscriber could have
            avoided by following Love is in Cyprus's advice to apply an update
            offered to him/her free of charge or for damage which was caused by
            him/her failing to correctly follow installation instructions or to
            have in place the minimum system requirements advised by Love is in
            Cyprus.
          </p>
          <br />
          <h4>8.4.4</h4>
          <p>
            Love is in Cyprus is not liable for business losses. Love is in
            Cyprus will only supply the Services for domestic and private use.
            If a Member or Subscriber uses the Services for any commercial or
            business purpose Love is in Cyprus will have no liability towards
            him/her for any loss of profit, loss of business, business
            interruption, or loss of business opportunity.
          </p>
          <br />
          <h4>8.4.5</h4>
          <p>
            Love is in Cyprus does not exclude or limit in any way its liability
            to Members or Subscribers where it would be unlawful to do so.
          </p>
          <br />
          <h4>8.4.6</h4>
          <p>
            In the event Love is in Cyprus or Love is in Cyprus is held liable
            on the basis of a breach by a Member or Subscriber of any of his/her
            legal or contractual obligations under the Terms of Use, the latter
            agrees to indemnify and hold Love is in Cyprus and/or Love is in
            Cyprus harmless against any damages, expenses or orders issued
            against it resulting from the Member's or Subscriber's breach of
            his/her obligations.
          </p>
          <h3>
            Article 9. Profile suspension - Account closure - Subscription
            termination
          </h3>
          <h4>9.1 Profile suspension</h4>
          <p>
            Each Member or Subscriber may at any moment request the suspension
            of his/her Profile, namely through the 'My Account' section
            dedicated to this purpose, in order to no longer be visible on the
            Website nor receive notifications. This suspension is temporary and
            does not interrupt the Subscription. The Member or Subscriber may
            reactivate his/her Profile at any moment.
          </p>
          <br />
          <h4>9.2 Account closure</h4>
          <p>
            Each Member or Subscriber may at any moment terminate his/her
            registration to the Services or his/her Subscription by requesting
            the closure of his/her Account from Love is in Cyprus, without
            further charges other than those connected to the transmission of
            his/her request and without reason, namely through the 'My Account'
            section, by email, using the{' '}
            <Link to='/contact-us'>contact form</Link> or by any means which may
            be indicated in this section. This request shall be deemed made on
            the working day following the one the request for closure of the
            relevant Account is received by Love is in Cyprus and the Subscriber
            may no longer use his/her Subscription. The Member or Subscriber
            will be informed by email about the closure of his/her Account. Once
            past the 14-day trial period after the date of subscription, all
            charges and purchases are nonrefundable, there are no refunds or
            credits for partially used periods.
          </p>
          <br />
          <h4>9.3 Non-renewal/Subscription termination by the Subscriber</h4>
          <p>
            The Subscriber may terminate the automatic renewal of his/her
            Subscription 24 hours after its purchase and at least 48 hours
            before the Subscription is renewed, by going to the 'My Account'
            section. The termination will take effect on the expiry date of the
            Subscription. Termination of the Subscription will be confirmed to
            the Subscriber by email. The Subscriber will not have access to the
            Paid Services from the date his/her Subscription expires.
          </p>
          <br />
          <h4>9.4 Termination by Love is in Cyprus</h4>
          <p>
            Without prejudice to the other provisions of the Terms of Use, in
            the event of a serious breach by a Subscriber or Member, especially
            in the event of breach of either the General Obligations or the
            Essential Obligations, Love is in Cyprus may permanently remove the
            Account(s) of the relevant Member or Subscriber from all the Website
            and Services, without prior notice. This removal will not give a
            right of reimbursement to the Subscriber. The Member or Subscriber
            will be informed by email about the closure of his/her Account. This
            closure will take effect without prejudice to any damages and
            interest which may be claimed by Love is in Cyprus from the Member
            or Subscriber or his/her successors and legal representatives as
            restitution for the losses incurred by Love is in Cyprus as a result
            of these breaches.
          </p>
          <h3>Article 10. Events and Activities</h3>
          <p>
            When Events or Activities are offered to Members and Subscribers on
            the Websites, specific terms and conditions are applicable. You may
            read these on the pages describing the Events or Activities.
          </p>
          <h3>Article 11. Personal Data</h3>
          <p>
            The personal data of Members and Subscribers are processed in
            accordance with the privacy policy applicable to Love is in Cyprus,
            which is available <Link to='/privacy-policy'>here</Link>.
          </p>
          <h3>Article 12. Entire Agreement</h3>
          <p>
            These Terms of Use form a contract, which governs the relationship
            between the Member or Subscriber and Love is in Cyprus. They cancel
            and supersede all previous provisions not expressly referred to or
            appended and provide all rights and obligations of Love is in
            Cyprus, of the companies of Love is in Cyprus and the Member or
            Subscriber with respect to their subject matter.
          </p>
          <br />
          <p>
            If one or more provisions in the Terms of Use are held to be
            unenforceable under applicable laws or regulations or as a result of
            a final decision by a competent court or authority, the other
            provisions will remain in full force and effect, to the extent
            permitted by law, regulation or applicable court decisions.
          </p>
          <h3>Article 13. Modification of the Services or Terms of Use</h3>
          <h4>13.1 Modification of the Services</h4>
          <p>
            Love is in Cyprus may update and modify the content and/or features
            of the Services at any moment in order to improve their quality. The
            Member or Subscriber will be informed of the nature of these updates
            or modifications when they are implemented on the Websites.
          </p>
          <br />
          <h4>13.2 Modification of the Terms of Use</h4>
          <p>
            Love is in Cyprus may modify the Terms of Use at any moment. The
            Member or Subscriber will be informed of those modifications when
            they are implemented on the Website. Use of the Websites and new
            registrations are subject to the Terms of Use currently in force.
            Users of the Websites and Members who do not agree to the
            modification of the Terms of Use may stop using the Websites.
          </p>
          <br />
          <p>
            Subscribers who subscribe before the modification of the Terms of
            Use will have a choice between two options:
          </p>
          <br />
          <div className='bullets'>
            <ul>
              <li>
                The Subscriber may accept the new Terms of Use by continuing to
                use the Websites;
              </li>
              <li>
                The Subscriber may demand the use of the Terms of Use, which
                were in force when he/she purchased his/her Subscription, for
                the duration of the initial period of validity of his/her
                Subscription only and excluding any renewal periods. He/she must
                request this expressly within one (1) month from the date the
                modified Terms of Use enter into force using the contact details
                included in Article 14 below. After expiry of the initial period
                of validity of his/her Subscription, only the modified Terms of
                Use shall be applicable to such Subscription. If the Subscriber
                fails to request this within one (1) month from their entry into
                force, the modified Terms of Use will be deemed accepted by the
                Subscriber.
              </li>
            </ul>
          </div>
          <h3>Article 14. Requests and Claims</h3>
          <h4>Refunds</h4>
          <p>
            You get a 14-day cooling-off period on any subscription purchase. To
            request a refund or exchange, just ask our Customer Care team.
            Please note that refunds are available for purchases of first-time
            subscriptions, and the refund total is calculated on a pro-rata
            basis. No refunds will be given for renewals, Boosts or Incognito
            purchases. We are unable to offer reimbursement beyond the initial
            14 days. Once this time has passed, we're here to help you make the
            most of your subscription by taking full advantage of the service
            features. If you don't want to continue with your subscription, you
            can cancel the automatic renewal in 'My account settings'. For every
            request or claim relevant to a subscription, the Member or
            Subscriber may contact Love is in Cyprus by completing the{' '}
            <Link to='/contact-us'>contact form</Link>, ensuring that he/she
            indicates his/her username, customer number and email address.
          </p>
          <br />
          <p>The Member or Subscriber may also contact the Customer Care</p>
          <div className='bullets'>
            <ul>
              <li>by email: customercare@loveisincyprus.com</li>
            </ul>
          </div>
          <h3>Article 15. Applicable law - Disputes</h3>
          <p>
            These Terms of Use are governed, interpreted and applied in
            accordance with the laws the Republic of Cyprus. The language of
            interpretation is English. In accordance with the rules applicable
            to mediation, prior to any request for mediation, the Member or
            Subscriber must first raise any dispute with Love is in Cyprus in
            writing in order to attempt an amicable resolution.
          </p>
          <br />
          <p>
            In the event that an amicable solution cannot be reached regarding a
            dispute relating to the Terms of Use, the competent authorities will
            be the courts of the Republic of Cyprus.
          </p>
          <h3>Article 16. About us</h3>
          <p>
            The Services and Products provided by Love is in Cyprus to Members
            and Subscribers pursuant to these Terms of Use are offered by Ex
            Florum Limited, a private limited company incorporated in Cyprus
            with registered company number HE 404391 (VAT number 10404391R)
            whose address is Agiou Athanasiou 16-2, 8560 Peyia, Cyprus. The
            Website is hosted on Love is in Cyprus's server(s)
          </p>
          <br />
          <p>
            For any questions, you can contact Love is in Cyprus by filling in
            the <Link to='/contact-us'>contact form</Link>.
          </p>
        </div>
      </div>
    </>
  );
};

export default TsAndCs;
